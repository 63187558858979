import * as React from 'react'

export default function SvgArrowUpArrowDown(
  props: HTMLOrSVGElement,
): React.ReactElement {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2929 18.2072C10.9024 17.8167 10.9024 17.1835 11.2929 16.793C11.6534 16.4325 12.2206 16.4048 12.6129 16.7098L12.7071 16.793L15.5 19.5861V11.0001C15.5 10.4872 15.886 10.0646 16.3834 10.0068L16.5 10.0001C17.0128 10.0001 17.4355 10.3861 17.4933 10.8834L17.5 11.0001V19.5861L20.2929 16.793C20.6534 16.4325 21.2206 16.4048 21.6129 16.7098L21.7071 16.793C22.0676 17.1535 22.0953 17.7207 21.7903 18.113L21.7071 18.2072L17.2071 22.7072L17.0953 22.8037L16.9841 22.8754L16.8713 22.9289L16.766 22.9642L16.6485 22.9891L16.5893 22.9961L16.4808 22.9999L16.4107 22.9961L16.2993 22.9798L16.1879 22.9503L16.0768 22.9064L15.9793 22.8541L15.8833 22.7873C15.8515 22.7624 15.8214 22.7357 15.7929 22.7072L11.2929 18.2072ZM2.29289 5.79302L6.79289 1.29302C6.82829 1.25762 6.86567 1.22542 6.90469 1.19642L7.01594 1.12472L7.12866 1.07132L7.23401 1.03592L7.35153 1.01112L7.40997 1.00412L7.5 1.00012L7.57524 1.00282L7.70073 1.02032L7.81214 1.04982L7.92322 1.09372L8.02071 1.14612L8.12545 1.21972L8.20711 1.29302L12.7071 5.79302C13.0976 6.18352 13.0976 6.81662 12.7071 7.20722C12.3166 7.59772 11.6834 7.59772 11.2929 7.20722L8.5 4.41512V13.0001C8.5 13.5523 8.05228 14.0001 7.5 14.0001C6.94772 14.0001 6.5 13.5523 6.5 13.0001V4.41512L3.70711 7.20722C3.31658 7.59772 2.68342 7.59772 2.29289 7.20722C1.90237 6.81662 1.90237 6.18352 2.29289 5.79302Z"
        fill="#75817E"
      />
    </svg>
  )
}
