import * as React from 'react'
export default function SvgReorder(
  props: HTMLOrSVGElement,
): React.ReactElement<SVGElement> {
  return (
    <svg {...props} viewBox="0 0 20 18">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2929 10.2929C15.6534 9.9324 16.2206 9.9047 16.6129 10.2097L16.7071 10.2929L19.7071 13.2929L19.7485 13.3369L19.8037 13.4047L19.8753 13.5159L19.9288 13.6287L19.9642 13.734L19.9932 13.8825L20 14L19.9972 14.0752L19.9798 14.2007L19.9503 14.3121L19.9063 14.4232L19.854 14.5207L19.7872 14.6168L19.7071 14.7071L16.7071 17.7071C16.3166 18.0976 15.6834 18.0976 15.2929 17.7071C14.9324 17.3466 14.9047 16.7794 15.2097 16.3871L15.2929 16.2929L16.585 14.999L13.8907 15C13.0403 15 12.2298 14.639 11.6608 14.0069L9.845 11.989L11.191 10.494L13.1474 12.669C13.3371 12.8797 13.6072 13 13.8907 13L16.585 12.999L15.2929 11.7071C14.9324 11.3466 14.9047 10.7794 15.2097 10.3871L15.2929 10.2929ZM15.2929 0.292891C15.6534 -0.0675893 16.2206 -0.0953193 16.6129 0.209701L16.7071 0.292891L19.7071 3.29289L19.7485 3.33685L19.8241 3.43332L19.8753 3.51594L19.9288 3.62866L19.9642 3.73401L19.989 3.85153L19.9983 3.94075V4.05938L19.9931 4.11805L19.9726 4.23351L19.9401 4.34167L19.9063 4.42322L19.854 4.52071L19.7803 4.62545L19.7071 4.70711L16.7071 7.7071C16.3166 8.0976 15.6834 8.0976 15.2929 7.7071C14.9324 7.3466 14.9047 6.77939 15.2097 6.3871L15.2929 6.29289L16.585 4.999L13.8907 5C13.6477 5 13.4145 5.0884 13.2334 5.24635L13.1474 5.33104L5.33916 14.0069C4.81399 14.5904 4.083 14.9429 3.30473 14.9936L3.10928 15H1C0.44772 15 0 14.5523 0 14C0 13.4872 0.38604 13.0645 0.88338 13.0067L1 13H3.10928C3.35226 13 3.58546 12.9116 3.76656 12.7536L3.85257 12.669L11.6608 3.99311C12.186 3.40959 12.917 3.05714 13.6953 3.00637L13.8907 3L16.585 2.999L15.2929 1.70711C14.9324 1.34662 14.9047 0.779391 15.2097 0.387101L15.2929 0.292891ZM3.10928 3C3.95973 3 4.77023 3.36096 5.33916 3.99311L7.155 6.01L5.809 7.505L3.85257 5.33104C3.66293 5.12032 3.39276 5 3.10928 5H1C0.44772 5 0 4.55228 0 4C0 3.44772 0.44772 3 1 3H3.10928Z"
        fill="currentColor"
      />
    </svg>
  )
}
