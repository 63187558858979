import * as React from 'react'

export default function SvgArrowRotating(
  props: HTMLOrSVGElement,
): React.ReactElement {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.34998 2.35292C3.97998 0.722922 6.28998 -0.217078 8.82998 0.0429219C12.5 0.412922 15.52 3.39292 15.93 7.06292C16.48 11.9129 12.73 16.0029 7.99998 16.0029C4.80998 16.0029 2.06998 14.1329 0.78998 11.4429C0.46998 10.7729 0.94998 10.0029 1.68998 10.0029C2.05998 10.0029 2.40998 10.2029 2.56998 10.5329C3.69998 12.9629 6.40998 14.5029 9.36998 13.8429C11.59 13.3529 13.38 11.5429 13.85 9.32292C14.69 5.44292 11.74 2.00292 7.99998 2.00292C6.33998 2.00292 4.85998 2.69292 3.77998 3.78292L5.28998 5.29292C5.91998 5.92292 5.47998 7.00292 4.58998 7.00292H0.999979C0.449979 7.00292 -2.09808e-05 6.55292 -2.09808e-05 6.00292V2.41292C-2.09808e-05 1.52292 1.07998 1.07292 1.70998 1.70292L2.34998 2.35292Z"
        fill="#75817E"
      />
    </svg>
  )
}
